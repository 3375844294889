import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Span, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Iron Haven Gym
			</title>
			<meta name={"description"} content={"Сила за пределами границ"} />
			<meta property={"og:title"} content={"Главная | Iron Haven Gym"} />
			<meta property={"og:description"} content={"Сила за пределами границ"} />
			<meta property={"og:image"} content={"https://vibratpanacea.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vibratpanacea.com/img/9043206324.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vibratpanacea.com/img/9043206324.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vibratpanacea.com/img/9043206324.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vibratpanacea.com/img/9043206324.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vibratpanacea.com/img/9043206324.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vibratpanacea.com/img/9043206324.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="Hero-16">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="0px 80px 0px 0px"
					lg-padding="0px 0 0px 0px"
					lg-margin="0px 0px 50px 0px"
					align-items="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
					Iron Haven
					</Text>
					<Text margin="0px 0px 50px 0px" font="--base" color="#8b9197">
					Добро пожаловать в Iron Haven Gym, где ваш фитнес-путь достигнет новых высот. В Iron Haven мы верим в то, что каждый человек может полностью раскрыть свой потенциал. Независимо от того, являетесь ли вы опытным спортсменом или только начинаете, наше современное оборудование и поддерживающее сообщество помогут вам на каждом шагу.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-self="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-primary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--color-primary"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							sm-width="100%"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Связаться с нами
						</Button>
					</Box>
				</Box>
				<Image
					src="https://vibratpanacea.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
			>
				<Image max-width="500px" src="https://vibratpanacea.com/img/2.jpg" md-margin="0px 0px 20px 0px" />
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base">
				Повысьте свой уровень фитнеса
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--dark" font="--headline2" md-margin="0px 0px 30px 0px">
				О тренажерном зале 
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
				Мы стремимся предоставить первоклассное оборудование, квалифицированные рекомендации и широкий спектр занятий, отвечающих самым разным потребностям наших членов. Наш современный, хорошо обслуживаемый тренажерный зал приспособлен для поддержки ваших целей в области здоровья и хорошего самочувствия, гарантируя, что каждая тренировка будет приятной и эффективной.
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-19"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					lg-max-width="720px"
				>
					Почему стоит выбрать Iron Haven Gym?
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text
							padding="0"
							margin="0"
							color="--greyD2"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
						>
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Современное оборудование: {" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Мы предлагаем новейшие фитнес-технологии и оборудование для повышения эффективности ваших тренировок.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text
							padding="0"
							margin="0"
							color="--darkL2"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
						>
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Экспертный персонал: {" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Наши сертифицированные тренеры и дружелюбный персонал всегда готовы подсказать, поддержать и мотивировать.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text
							padding="0"
							margin="0"
							color="--darkL2"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
						>
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Разнообразие занятий: {" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								От йоги до высокоинтенсивных интервальных тренировок - в нашем разнообразии занятий каждый найдет что-то для себя.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" lg-max-width="720px">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Атмосфера сообщества: {" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Присоединяйтесь к сообществу единомышленников, которые разделяют вашу страсть к фитнесу и хорошему самочувствию.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Box
					width="972px"
					height="589px"
					md-display="flex"
					md-justify-content="center"
					sm-height="300px"
					lg-height="auto"
				>
					<Image
						box-shadow="0 10px 20px -10px rgba(43, 45, 84, 0.15),0 0px 20px 0 rgba(43, 45, 84, 0.05)"
						src="https://vibratpanacea.com/img/3.jpg"
						height="589px"
						lg-width="100%"
						width="972px"
						border-radius="8px"
						z-index="9"
						position="relative"
						lg-margin="0px 0px 0px 0px"
						sm-height="300px"
						object-fit="cover"
						lg-height="auto"
						lg-display="block"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});